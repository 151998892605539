import moment from "moment";
import React from "react";
import history from "../../history";

export class HorizontalTimetable extends React.Component<{
  currentDate: moment.Moment;
  hijriDateString: string;
  salahTimes: any;
}> {
  handleClickEvent = () => {
    history.push("/timetable-screen/");
  };
  render() {
    return (
      <div id="Timetable">
        <div className="button-links-desktop">
          <a
            href="https://jalaliajaamemosque.enthuse.com/membershipfee"
            className="btn btn-primary donate-button-desktop"
          >
            Membership Fee
          </a>
          <a
            href="https://jalaliajaamemosque.enthuse.com/donation"
            className="btn btn-primary donate-button-desktop"
          >
            Donate
          </a>
          {/* <a
            href="https://givebrite.com/land-purchase"
            className="btn btn-primary donate-button-desktop"
          >
            Rohingya Appeal
          </a> */}
        </div>
        <div className="current-date text-center mb-2">
          <p className="text-center font-weight-bold">
          Jumu’ah Prayer Time: 1.30pm
          {/* Eid Prayers: 8am, 9.30am & 10.30am */}
          </p>
          {this.props.currentDate.format("Do MMMM YYYY")} -{" "}
          {this.props.hijriDateString}
        </div>

        {this.props.salahTimes && (
          <div className="table-container">
            <table className="salah-times-table">
              <thead>
                <tr>
                  <th>
                    <a href="/timetable-screen">
                      <i
                        className="fas fa-mobile-alt desktop-hidden mobile-screen"
                        style={{ fontSize: "20px" }}
                      ></i>
                    </a>
                  </th>
                  <th>Fajr</th>
                  <th>Sunrise</th>
                  <th>Zuhr</th>
                  <th>Asr</th>
                  <th>Maghrib</th>
                  <th>Isha</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="text-muted">Begins</td>
                  <td className={"prayer-time"}>
                    {moment
                      .utc(this.props.salahTimes.fajrStart, "HH:mm:ss")
                      .format("h:mm")}
                  </td>
                  <td className={"prayer-time"}>
                    {moment
                      .utc(this.props.salahTimes.sunrise, "HH:mm:ss")
                      .format("h:mm")}
                  </td>
                  <td className={"prayer-time"}>
                    {moment
                      .utc(this.props.salahTimes.zuhrStart, "HH:mm:ss")
                      .format("h:mm")}
                  </td>
                  <td className={"prayer-time"}>
                    {moment
                      .utc(this.props.salahTimes.asrStart, "HH:mm:ss")
                      .format("h:mm")}
                  </td>
                  <td className={"prayer-time"}>
                    {moment
                      .utc(this.props.salahTimes.magrib, "HH:mm:ss")
                      .format("h:mm")}
                  </td>
                  <td className={"prayer-time"}>
                    {moment
                      .utc(this.props.salahTimes.ishaStart, "HH:mm:ss")
                      .format("h:mm")}
                  </td>
                </tr>
                <tr>
                  <td className="text-muted">Jama'ah</td>
                  <td className={"prayer-time"}>
                    {moment
                      .utc(this.props.salahTimes.fajrJamat, "HH:mm:ss")
                      .format("h:mm")}
                  </td>
                  <td className={"prayer-time"}></td>
                  <td className={"prayer-time"}>
                    {moment
                      .utc(this.props.salahTimes.zuhrJamat, "HH:mm:ss")
                      .format("h:mm")}
                  </td>
                  <td className={"prayer-time"}>
                    {moment
                      .utc(this.props.salahTimes.asrJamat, "HH:mm:ss")
                      .format("h:mm")}
                  </td>
                  <td className={"prayer-time"}>
                    {moment
                      .utc(this.props.salahTimes.magrib, "HH:mm:ss")
                      .format("h:mm")}
                  </td>
                  <td className={"prayer-time"}>
                    {moment
                      .utc(this.props.salahTimes.ishaJamat, "HH:mm:ss")
                      .format("h:mm")}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}

        <div className="clearfix"></div>
      </div>
    );
  }
}
